import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/imovel`;

class ImovelService {

    getImoveisCadastrados(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + '/' + matricula);
    }
}

export default new ImovelService();