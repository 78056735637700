<template>
    <EntregaProducao :matricula="currentUser.matricula" />
</template>

<script lang="js">
    import EntregaProducao from '../../components/consulta/EntregaProducao.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { EntregaProducao }
    }

</script>