import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/cultura`;

class CulturaService {
    getCulturas() {
        return axios.get(API_BASE_URL + RESOURCE_V1);
    }
}

export default new CulturaService();