import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/entrega-producao`;

class EntregaProducaoService {
    getSafra(matricula, codigoImovel, safraComposta, cultura) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/safra/" + matricula + "/" + codigoImovel + "/" + safraComposta + "/" + cultura);
    }
}

export default new EntregaProducaoService();