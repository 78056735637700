<template>
    <div class="col-12">
        <div class="card">
            <h5>Entrega de Produção</h5>
            
            <Message severity="info" :closable="true">Consulta da sua entrega de produção dos últimos três anos. Informe um período.</Message>

            <div class="flex align-items-center" style="width: 100%;">
                <div class="formgroup-inline">
                <AutoComplete 
                    placeholder="Safra"
                    :dropdown="true"
                    v-model="safraSelecionada" 
                    :suggestions="safrasFiltradas" 
                    @complete="buscarSafra($event)"
                    field="descricao"
                    class="col-12 md:col-3"
                />

                <AutoComplete 
                    placeholder="Cultura"
                    :dropdown="true" 
                    v-model="culturaSelecionada" 
                    :suggestions="culturasFiltradas" 
                    @complete="buscarCultura($event)"
                    field="descricao"
                    class="col-12 md:col-3"
                />
                
                <AutoComplete 
                    placeholder="Imóvel"
                    :dropdown="true" 
                    v-model="imovelSelecionado" 
                    :suggestions="imoveisFiltrados" 
                    @complete="buscarImoveis($event)"
                    field="nome"
                    class="col-12 md:col-6"
                />
                <Button label="Consultar" @click="consultar" class="col-12 md:col-3" icon="pi pi-search"></Button>
            </div>
            </div>

            <br />

            <TabView>
                <TabPanel header="Resumido">
                    <div class="flex justify-content-between">
                        <strong class="flex-order-2">{{ qtdResultado }} resultado(s) encontrado(s).</strong>

                        <Button 
                            label="Exportar" 
                            icon="pi pi-file-excel"
                            class="p-button-warning" 
                            @click="exportar"
                            :disabled="!exportarXls"
                        />
                    </div>

                    <br />

                    <DataTable :value="consultaSafra" 
                            responsiveLayout="stack"
                            :loading="loading" >
                        <template #empty>
                            Nenhum registro encontrado.
                        </template>
                        <template #loading>
                            Carregando... Por favor, aguarde.
                        </template>
                        <Column field="dpi" header="DPI" sortable>
                            <template #body="{data}">
                                <i v-if="data.dpi" class="pi pi-dollar" style="font-size: 1rem" title="DPI"></i>
                            </template>
                        </Column>
                        <Column field="numeroDocumentoPesagem" header="Núm" sortable />
                        <Column field="dataHoraFormatada" header="Data" sortable />
                        <Column field="placa" header="Placa" sortable />
                        <Column field="imovel" header="Imóvel" sortable />
                        <Column field="produto" header="Produto" sortable />
                        <Column field="pesoBruto" header="Peso Liq" sortable>
                            <template #body="{data}">
                                {{ formatDecimal(data.pesoBruto, 0, 5) }} Kg
                            </template>
                        </Column>
                        <Column field="rendaLiquida" header="Renda Liq" sortable>
                            <template #body="{data}">
                                {{ formatDecimal(data.rendaLiquida, 0, 5) }} Kg
                            </template>
                        </Column>
                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="6" footerStyle="text-align: right;"/>
                                <Column footerStyle="text-align: right;">
                                    <template #footer>
                                        {{ formatDecimal(totalPesoLiquido, 0, 5) }} Kg
                                    </template>
                                </Column>
                                <Column footerStyle="text-align: right;">
                                    <template #footer>
                                        {{ formatDecimal(totalRendaLiquida, 0, 5) }} Kg
                                    </template>
                                </Column>
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </TabPanel>
                <TabPanel header="Detalhado">
                    <div class="flex justify-content-end">
                        <strong>{{ qtdResultado }} resultado(s) encontrado(s).</strong>
                    </div>

                    <br />

                    <Accordion :multiple="true" :activeIdex="0">
                        <AccordionTab v-for="item in consultaSafra" :key="item.numeroReciboEntrega" :header=" 'RE: ' + item.numeroReciboEntrega">
                            <div class="grid">
                                <div class="col-6 md:col-1">
                                    <strong>Ticket:</strong>
                                </div>
                                <div class="col-6 md:col-4">
                                    <strong>{{ formatDecimal(item.numeroDocumentoPesagem, 0, 0) }}</strong>
                                </div>

                                <div class="col-6 md:col-1">
                                    <strong>Integrada:</strong>
                                </div>
                                <div class="col-6 md:col-6">
                                    <strong>{{ item.nomeEstabelecimento }}</strong>
                                </div>
                            </div>

                            <div class="grid">
                                <div class="col-6 md:col-1">
                                    <strong>Data/Hora:</strong>
                                </div>
                                <div class="col-6 md:col-4">
                                    {{ item.dataHoraFormatada }}
                                </div>

                                <div class="col-6 md:col-1">
                                    <strong>NF prod.:</strong>
                                </div>
                                <div class="col-6 md:col-2">
                                    {{ item.numeroNotaFiscalProdutor }}
                                </div>

                                <div class="col-6 md:col-1">
                                    <strong>Imóvel:</strong>
                                </div>
                                <div class="col-6 md:col-3">
                                    {{ item.imovel }}
                                </div>
                            </div>

                            <div class="grid">
                                <div class="col-6 md:col-1">
                                    <strong>Produto:</strong>
                                </div>
                                <div class="col-6 md:col-4">
                                    {{ item.produto }}
                                </div>

                                <div class="col-6 md:col-1">
                                    <strong>Placa:</strong>
                                </div>
                                <div class="col-6 md:col-2">
                                    {{ item.placa }}
                                </div>

                                <div class="col-6 md:col-1">
                                    <strong>Motorista:</strong>
                                </div>
                                <div class="col-6 md:col-3">
                                    {{ item.nomeMotorista }}
                                </div>
                            </div>

                            <Fieldset legend="Classificação">
                                <!-- Linha 1 -->
                                <div class="grid">
                                    <div class="col-6 md:col-2">
                                        <strong>Chuv/Avari:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoChuvadoAvariado, 0, 5) }}
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>Desconto:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoPercentualDescontoChuvadoAvariado, 0, 5) }} % ({{ formatDecimal(item.classificacaoQuantidadeDescontoChuvadoAvariado, 0, 5) }}) kg
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>FNT:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.fnt, 0, 5) }}
                                    </div>
                                </div>

                                <!-- Linha 3 -->
                                <div class="grid">
                                    <div class="col-6 md:col-2">
                                        <strong>Impureza:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoImpuresa, 0, 5) }}
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>Desconto:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoPercentualDescontoImpureza, 0, 5) }} % ({{ formatDecimal(item.classificacaoQuantidadeDescontoImpureza, 0, 5) }}) kg
                                    </div>
                                </div>

                                <!-- Linha 4 -->
                                <div class="grid">
                                    <div class="col-6 md:col-2">
                                        <strong>Umidade:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoUmidade, 0, 5) }}
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>Desconto:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoPercentualDescontoUmidade, 0, 5) }} % ({{ formatDecimal(item.classificacaoQuantidadeDescontoUmidade, 0, 5) }}) kg
                                    </div>
                                </div>

                                <!-- Linha 5 -->
                                <div class="grid">
                                    <div class="col-6 md:col-2">
                                        <strong>Ph:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoPhEntrada, 0, 5) }}
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>Ph corrigido:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoPhCorrigido, 0, 5) }}
                                    </div>
                                </div>

                                <!-- Linha 6 -->
                                <div class="grid">
                                    <div class="col-6 md:col-2">
                                        <strong>TBM:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        {{ formatDecimal(item.classificacaoTbm, 0, 5) }}
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>Desconto:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        ({{ formatDecimal(item.classificacaoQuantidadeDescontoTbm, 0, 5) }}) kg
                                    </div>
                                </div>

                                <!-- Linha 7 -->
                                <div class="grid">
                                    <div class="col-6 md:col-2">
                                        <strong>Secagem:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        ({{ formatDecimal(item.taxaSaoPauloQtdeSecagem, 0, 5) }}) kg
                                    </div>

                                    <div class="col-6 md:col-2">
                                        <strong>Recepção:</strong>
                                    </div>
                                    <div class="col-6 md:col-2">
                                        ({{ formatDecimal(item.taxaSaoPauloQtdeRecepcao, 0, 5) }}) kg
                                    </div>
                                </div>
                            </Fieldset>

                            <br />

                            <!-- Linha -->
                            <div class="grid">
                                <div class="col-6 md:col-2">
                                    <strong>Peso líquido:</strong>
                                </div>
                                <div class="col-6 md:col-2">
                                    {{ formatDecimal(item.pesoBruto, 0, 5) }} kg
                                </div>
                            </div>

                            <!-- Linha -->
                            <div class="grid">
                                <div class="col-6 md:col-2">
                                    <strong>Renda líquida:</strong>
                                </div>
                                <div class="col-6 md:col-2">
                                    {{ formatDecimal(item.rendaLiquida, 0, 5) }} kg
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    <br />

                    <div class="flex justify-content-end">
                        <div class="mr-5">
                            <strong>Total peso líquido:&nbsp;</strong>
                            <span>{{ formatDecimal(totalPesoLiquido, 0, 5) }} Kg.</span>
                        </div>
                        <div class="mr-5">
                            <strong>Total renda líquida:&nbsp;</strong>
                            <span>{{ formatDecimal(totalRendaLiquida, 0, 5) }} Kg.</span>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    </div>

</template>

<script lang="js">
    import SafrasService from '../../service/SafraService';
    import CulturaService from '../../service/CulturaService';
    import ImoveisService from '../../service/ImoveisService';
    import EntregaProducaoService from '../../service/EntregaProducaoService';
    import Formatacao from '../../utilities/Formatacao';
    import ExportarPlanilha from '../../utilities/ExportarPlanilha';

    export default {
        name: 'EntregaProducao', 
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

        data() {
            return {
                safras: [],
                safraSelecionada: null,
                safrasFiltradas: [],

                culturas: [],
                culturaSelecionada: null,
                culturasFiltradas: [],

                imoveis: [],
                imovelSelecionado: null,
                imoveisFiltrados: [],

                consultaSafra: [],
                qtdResultado: 0,
                totalPesoLiquido: 0,
                totalRendaLiquida: 0,
                exportarXls: false,
                
                loading: false,
            }
        },

		mounted() {
            SafrasService.getSafras().then(({ data }) => {
                if(data) {
                    this.safras = data;
                }
            });

            CulturaService.getCulturas().then(({ data }) => {
                if(data) {
                    this.culturas = data;
                }

                this.culturas.unshift({
                    codigo: "*",
                    descricao: "Todas"
                });
            });
            
            ImoveisService.getImoveisCadastrados(this.matricula).then(({ data }) => {
                if(data) {
                    this.imoveis = data;
                }

                this.imoveis.unshift({
                    matricula: 0,
                    nome: "Todos"
                })
            });
        },
        
        methods: {
            buscarSafra(event) {
                setTimeout(() => {
					if (!event.query.trim().length) {
						this.safrasFiltradas = [...this.safras];
					}
					else {
						this.safrasFiltradas = this.safras.filter((item) => {
							return item.safra.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 250);
            },

            buscarCultura(event) {
                setTimeout(() => {
					if (!event.query.trim().length) {
						this.culturasFiltradas = [...this.culturas];
					}
					else {
						this.culturasFiltradas = this.culturas.filter((item) => {
							return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 250);
            },

            buscarImoveis(event) {
                setTimeout(() => {
					if (!event.query.trim().length) {
						this.imoveisFiltrados = [...this.imoveis];
					}
					else {
						this.imoveisFiltrados = this.imoveis.filter((item) => {
							return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 250);
            },

            consultar() {
                this.carregarLoading();
                this.totalPesoLiquido = 0;
                this.totalRendaLiquida = 0;

                if(!this.safraSelecionada || typeof this.safraSelecionada == 'string') {
                    this.safraSelecionada = this.safras.length > 0 ? this.safras[0] : null;
                }

                if(!this.culturaSelecionada || typeof this.culturaSelecionada == 'string') {
                    this.culturaSelecionada = this.culturas.length > 0 ? this.culturas[0] : null;
                }

                if(!this.imovelSelecionado || typeof this.imovelSelecionado == 'string') {
                    this.imovelSelecionado = this.imoveis.length > 0 ? this.imoveis[0] : null;
                }

                EntregaProducaoService.getSafra(
                    this.matricula,
                    this.imovelSelecionado.matricula,
                    this.safraSelecionada.safraComposta,
                    this.culturaSelecionada.codigo
                ).then((response) => {
                    this.consultaSafra = response.data;
                    this.qtdResultado = this.consultaSafra.length;

                    for(let item of this.consultaSafra) {
                        this.totalPesoLiquido += item.pesoBruto;
                        this.totalRendaLiquida += item.rendaLiquida;
                    }

                    if(this.qtdResultado > 0) {
                        this.exportarXls = true;
                    }
                    else {
                        this.exportarXls = false;
                        this.totalPesoLiquido = 0;
                        this.totalRendaLiquida = 0;
                    }
                }).finally(() => {
                    this.carregarLoading();
                });
            },

			exportar() {
				const colunas = [
					{name: "numeroDocumentoPesagem", title: "Núm"},
					{name: "dataHoraFormatada", title: "Data"},
					{name: "nomeEstabelecimento", title: "Integrada"},
					{name: "placa", title: "Placa"},
					{name: "imovel", title: "Imóvel"},
					{name: "produto", title: "Produto"},
					{name: "classificacaoChuvadoAvariado", title: "Chuv/Avari"},
					{name: "classificacaoImpuresa", title: "Impureza"},
					{name: "classificacaoUmidade", title: "Umidade"},
					{name: "classificacaoPhEntrada", title: "Ph"},
					{name: "classificacaoTbm", title: "TBM"},
                    {name: "fnt", title: "FNT"},
					{name: "pesoBruto", title: "Peso líq"},
					{name: "rendaLiquida", title: "Renda líq"}
				];

				ExportarPlanilha({
					cols: colunas,
					rows: this.consultaSafra,
					types:{
						numeroDocumentoPesagem: 'n',
						classificacaoChuvadoAvariado : 'n',
                        classificacaoImpuresa : 'n',
                        classificacaoUmidade : 'n',
                        classificacaoPhEntrada : 'n',
                        classificacaoTbm : 'n',
                        pesoBruto : 'n',
                        rendaLiquida : 'n', 
                        fnt: 'n'
					},
					formats:{
						pesoBruto: '#,##0 "Kg"',
						rendaLiquida: '#,##0 "Kg"'
					},
					filename: `romaneio-entrega-${this.retornarData()}.xls`,
					bookType: 'biff8'
				});
			},

			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			},

            retornarData() {
                let data = new Date();
                let mes = String("0" + (data.getMonth() + 1)).slice(-2);
                let hora = String("0" + data.getHours()).slice(-2);
                let minuto = String("0" + data.getMinutes()).slice(-2);
                let segundo = String("0" + data.getSeconds()).slice(-2);
                return `${data.getFullYear()}${mes}${data.getDate()}${hora}${minuto}${segundo}`;
            },

            carregarLoading() {
                this.loading = !this.loading;
            },
        }
    }
    
</script>