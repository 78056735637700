import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/safra`;

class SafrasService {
    getSafras() {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/todas-safras");
    }
}

export default new SafrasService();